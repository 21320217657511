import { get } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import {
  Box,
  Chip,
  FormHelperText,
  ListItemText,
  Typography,
  FormControl,
  OutlinedInput,
  Select,
  InputLabel,
  MenuItem,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useFormContext, Controller } from 'react-hook-form';

const CustomPrinterTagsChip = ({
   name,
   sx,
   label,
   options,
   multiple = true,
   MenuProps,
   formControlProps,
   maxShow = 4,
   background = '#fafafa',
   ...params
 }) => {
  const { control, formState } = useFormContext();
  const { errors } = formState;
  let parsedError = get(errors, name);

  return (
    <Controller
      {...params}
      name={name}
      control={control}
      render={({ field }) => {
        const { value, onChange } = field;

        return (
          <FormControl
            size="small"
            fullWidth
            error={!!parsedError}
            sx={sx}
            {...formControlProps}
          >
            <InputLabel
              id="demo-multiple-chip-label"
              sx={{ background: background }}
            >
              {label}
            </InputLabel>

            <Select
              variant="outlined"
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              multiple={multiple}
              value={value || []}
              onChange={onChange}
              label={label}
              input={
                <OutlinedInput
                  id={`${name}-select-outlined`}
                  label={label}
                />
              }
              MenuProps={MenuProps}
              sx={{
                '& .MuiSelect-select': {
                  minHeight: '28px'
                },
              }}
              renderValue={(selected) => (
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 0.5,
                    padding: 0,
                  }}
                >
                  {selected.map((val, index) => {
                    const found = options.find((item) => item?.tagName === val);
                    if (index < maxShow) {
                      return (
                        <Chip
                          key={val}
                          sx={{
                            height: '26px',
                            fontWeight: 500,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width: found?.tagName?.length > 10 ? '110px' : 'inherit',
                          }}
                          label={found?.tagName}
                          onDelete={(event) => {
                            event.stopPropagation();
                            const updatedValue = value?.filter(item => item !== val);
                            onChange(updatedValue);
                          }}
                          onMouseDown={(event) => {
                            event.stopPropagation();
                          }}
                        />
                      );
                    }
                    return null;
                  })}
                  {selected?.length > maxShow ? (
                    <Chip
                      key={`+${selected?.length - maxShow}`}
                      sx={{ height: '26px', fontWeight: 500 }}
                      label={`${selected?.length - maxShow} more`}
                    />
                  ) : null}
                </Box>
              )}
              InputLabelProps={{
                shrink: true,
              }}
            >
              {options.map((opt) => (
                <MenuItem
                  key={opt?.id || uuidv4()}
                  value={opt?.tagName}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <ListItemText
                      disableTypography
                      primary={<Typography>{opt?.tagName}</Typography>}
                    />
                    {value?.includes(opt?.tagName) && (
                      <CheckIcon fontSize="small" style={{ color: 'background: rgba(29, 25, 43, 1)' }} />
                    )}
                  </Box>
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{parsedError?.message || ''}</FormHelperText>
          </FormControl>
        );
      }}
    />
  );
};

export default CustomPrinterTagsChip;
