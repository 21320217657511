import { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { deAuthorize } from 'redux/actions/Menu/menu';
import LabelTooltip from 'components/FormFields/LabelTooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { confirm } from 'components/MenuNew/ConfirmModal';
import * as all from 'constants/permissions';
import usePermission from 'hooks/permission';
import ChangePaymentSystemDialog from 'pages/WebsiteSettings/PaymentConfiguration/ChangePaymentSystemDialog';
import { PaymentButton } from 'redesign/Shared/styles';

const PAYMENT_SYSTEM_CONFIG = {
  stripe: 'Stripe',
  forward: 'Fiserv (Forward)',
}

const PaymentSettings = ({
 payment,
 onDisconnect,
 onConnect,
 setCreditCard,
 creditCard,
 register,
 model,
 openPaymentModal,
 setOpenPaymentModal,
 websiteFormMethods,
}) => {
  const { currentBusiness } = useSelector(({ business }) => business);
  const [connected, setConnected] = useState(false);
  const { t } = useTranslation();
  const checkPermission = usePermission();
  const openModal = () => setOpenPaymentModal(true);

  useEffect(() => {
    setConnected(payment);
  }, [payment]);
  console.log(model, 'model');
  const handleClose = () => setOpenPaymentModal(false);

  const handleDeAuthorize = useCallback(async () => {
    const confirmed = await confirm({
      title: t('providers_settings.are_you_sure'),
      message: t('providers_settings.merchant_needs_delivery_orders'),
      cancelText: t('orders.cancel'),
      confirmText: t('providers_settings.disconnect'),
      confirmColor: 'primary',
    });
    if (confirmed) {
      deAuthorize({
        clientId: process.env.REACT_APP_STRIPE_CLIENT_ID,
        stripeId: payment,
      }).then(() => {
        onDisconnect();
        setConnected(false);
      });
    }
  }, [onDisconnect, payment]);

  return (
    <div className="settings-payment mt-2 mb-4">
      <h5 className="mb-1"> {t('website_settings.payment_settings')}</h5>
      {openPaymentModal && (
        <ChangePaymentSystemDialog open={openPaymentModal} handleClose={handleClose} websiteFormMethods={websiteFormMethods} />
      )}
      <FormGroup>
        <FormControlLabel
          sx={{ width: 'max-content' }}
          control={
            <Switch
              {...register('payInStore')}
              defaultChecked={model.payInStore || false}
              label={t('website_settings.pay_in_store')}
              name="payInStore"
            />
          }
          label={t('website_settings.pay_in_store')}
        />
        <FormControlLabel
          sx={{ width: 'max-content' }}
          disabled={!payment || !connected}
          onChange={setCreditCard}
          control={
            <Switch
              checked={creditCard}
              // {...register('creditCard')}
              name="creditCard"
              // defaultChecked={model.creditCard || false}
            />
          }
          label={
            <LabelTooltip
              value={t('website_settings.credit_card')}
              id="deliveryTooltip1"
              tooltip="Not available without Merchant Integration!"
            />
          }
        />
        {/*<FormControlLabel*/}
        {/*  sx={{ width: 'max-content' }}*/}
        {/*  disabled*/}
        {/*  control={<Switch {...register('applePay')} defaultChecked={model.applePay || false} name="applePay" />}*/}
        {/*  label="Apple pay (coming soon)"*/}
        {/*/>*/}
      </FormGroup>

      <FormLabel> {t('website_settings.payment_settings')}</FormLabel>

      <Typography className="mb-3" variant="subtitle2" color="text.secondary">
        {t('website_settings.merchant_service_provided_delivery')}
        <br /> {t('Connect your existing payment service account or create a new one.')}
      </Typography>
      {model?.paymentSystem && (
        <Typography sx={{ mb: model?.payment ? 0 : 3 }}>
          Payment Service: {!connected ? 'N/A' : PAYMENT_SYSTEM_CONFIG[model.paymentSystem] || model.paymentSystem}
        </Typography>
      )}
      {model?.payment && (
        <Typography className="mb-3">
          Account Number: {!connected ? 'N/A' : model.payment}
        </Typography>
      )}

      <Box className="settings-payment-content mb-5">
        <>
          {connected ? (
            <Button
              id="paymentSettingsDisconnect"
              variant="outlined"
              disabled={true}
              color="error"
              onClick={handleDeAuthorize}
            >
              {t('website_settings.disconnect')}
            </Button>
          ) : (
            <Button
              id="paymentSettingsConect"
              variant="outlined"
              onClick={onConnect}
              color="success"
              href={`https://dashboard.stripe.com/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_STRIPE_CLIENT_ID}&scope=read_write&state=${currentBusiness._id}`}
            >
              {t('website_settings.connect')}
            </Button>
          )}
          {checkPermission(all['SUPER']) ? (
            <PaymentButton
              variant='contained'
              onClick={openModal}
            >Add/Edit Payment System</PaymentButton>
          ) : (<></>)}
        </>
      </Box>
    </div>
  );
};

export default PaymentSettings;
