import instance from 'services/api';
import { PRINTER_TYPES } from 'constants/printers';
import { GET_PRINTER_TAGS_LIST, SET_PRINTERS_LIST, SET_PRINTERS_LIST_LOADING } from 'redux/actions/types';

export const getPrintersList = () => (dispatch) => {
  dispatch({
    type: SET_PRINTERS_LIST_LOADING,
    payload: true,
  });
  try {
    instance
      .get('/printer/list')
      .then(({ data: { data } }) => {
        return dispatch({
          type: SET_PRINTERS_LIST,
          payload: {
            data,
          },
        });
      })
      .finally(() => {
        dispatch({
          type: SET_PRINTERS_LIST_LOADING,
          payload: false,
        });
      });
  } catch (err) {
    return Promise.reject(err);
  }
};

export const addPrinter = (body) => (dispatch) => {
  body.status = true;
  if (body.type === PRINTER_TYPES.epson.toLowerCase() || body.type === PRINTER_TYPES.other.toLowerCase()) {
    body.templateType = 'react-native-thermal-receipt-printer';
  }
  try {
    return instance.post('/printer', body).then(({ data: { data } }) => {
      return dispatch(getPrintersList());
    });
  } catch (err) {
    return Promise.reject(err);
  }
};

export const updatePrinter = (body) => (dispatch) => {
  try {
    const printerId = body?.id;
    delete body?.id;
    return instance.put(`/printer/${printerId}`, body).then(() => {
      return dispatch(getPrintersList());
    });
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deletePrinter = (id) => (dispatch) => {
  try {
    return instance.delete(`/printer/${id}`).then(() => {
      return dispatch(getPrintersList());
    });
  } catch (err) {
    return Promise.reject(err);
  }
};

/*-----------------------------------Tags-------------------------------------*/
export const getPrinterTags = () => (dispatch) =>  {
  try {
    instance.get(`/printer-tag/list`).then((res) => {
      return dispatch({
        type: GET_PRINTER_TAGS_LIST,
        payload: res?.data?.data || [],
      });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}

export const createPrinterTags = (data) => (dispatch) => {
  try {
    return instance.post(`/printer-tag`, data).then(() => {
      return dispatch(getPrinterTags());
    });
  }catch (err) {
    return Promise.reject(err);
  }
}

export const updatePrinterTags = (data) => (dispatch) => {
  try {
    const tagId = data?.id;
    delete data?.id;
    return instance.put(`/printer-tag/${tagId}`, data).then(() => {
      return dispatch(getPrinterTags());
    });
  }catch (err) {
    return Promise.reject(err);
  }
}

export const deletePrinterTags = (tagId) => (dispatch) => {
  try {
    return instance.delete(`/printer-tag/${tagId}`).then(() => {
      return dispatch(getPrinterTags());
    });
  }catch (err) {
    return Promise.reject(err);
  }
}