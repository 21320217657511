import { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DeleteIcon from 'assets/icons/TableDelete.png';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { confirm } from 'components/MenuNew/ConfirmModal';
import DoneCheckMark from 'assets/images/doneCheckMark.png';
import { DownloadCopyButtonTitle } from 'redesign/BusinessTab/styles';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { selectCurrentBusiness, selectPrinterList } from 'redux/selectors';
import { MAX_ITEM_COUNT_IN_PRINTER, MODE } from 'pages/TableOrdering/config';
import { Box, Typography, IconButton, Tooltip, Popover } from '@mui/material';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { addPrinter, deletePrinter, getPrintersList, getPrinterTags, updatePrinter } from 'redux/actions/printers';
import {
  PrinterStatus,
  PrinterStatusBlock,
  PrinterStatusTitle,
  PrinterTicketStyle,
  TagsItem, TagsItemWrapper
} from 'redesign/Printers/styles';

const defaultValues = {
  default: false,
  ipAddress: '',
  name: '',
  port: '',
  type: '',
  ticketStyle: 'regular',
  innerMacAddress: '',
  printerTags: []
}

const schemaPrinterItem = yup.object().shape({
  name: yup.string().required('field_is_required_'),
  ipAddress: yup.string().when(['type', 'innerMacAddress'], {
    is: (type, innerMacAddress) => type != 'internal' && !innerMacAddress,
    then: yup
      .string()
      .matches(
        '^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$',
        'this_field_is_invalid_'
      )
      .required('field_is_required_'),
  }),
  ticketStyle: yup.string().required('field_is_required_'),
  port: yup
    .string()
    .nullable()
    .when(['type', 'innerMacAddress'], {
      is: (type, innerMacAddress) => type != 'internal' && !innerMacAddress,
      then: yup.string().required('field_is_required_'),
    }),

  type: yup.string().required('field_is_required_'),
  printerTags: yup.array(),
  _id: yup.string(),
});

const usePrinters = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const [mode, setMode] = useState(MODE.CREATE);
  const [loading, setLoading] = useState(false);
  const [openPrinter, setOpenPrinters] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(MAX_ITEM_COUNT_IN_PRINTER);
  const [anchorEl, setAnchorEl] = useState(null);

  const currentBusiness = useSelector(selectCurrentBusiness);
  const printerList = useSelector(selectPrinterList);

  const formMethods = useForm({
    defaultValues,
    mode: 'all',
    resolver: yupResolver(schemaPrinterItem),
  });

  useEffect(() => {
    if (currentBusiness?._id) {
      dispatch(getPrinterTags());
      dispatch(getPrintersList());
    }
  }, []);

  const {
    watch,
    setValue,
    resetField
  } = formMethods;

  watch((data, { name }) => {
    if (name === 'type') {
      resetField('ipAddress', '');
      resetField('port', '');
      setValue('port', '');
      setValue('ipAddress', '');
    }
  });

  const handlePopoverOpen = (event) => setAnchorEl(event.currentTarget);
  const handlePopoverClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  console.log(printerList, 'printerList');

  const printerListFiltered = printerList?.map((printer) => ({
    id: printer?._id,
    name: printer?.name || '',
    ipAddress: printer?.ipAddress || '',
    port: printer?.port || null,
    type: printer?.type || '',
    status: printer?.status,
    ticketStyle: printer?.ticketStyle || defaultValues.ticketStyle,
    printerTags: printer?.printerTags || [],
    isDefault: printer?.default,
    innerMacAddress: printer?.innerMacAddress ?? ''
  }));

  const iconStyle = useCallback(
    (isOnline) => {
      const baseStyle = { fontSize: '10px' };
      if (isOnline) {
        return {
          ...baseStyle,
          color: '#11AF22',
        };
      }
      return {
        ...baseStyle,
        fill: 'none',
        stroke: 'rgba(0, 0, 0, 0.24)',
        strokeWidth: 1.5,
      };
    },
    []
  );
  const handleEdit = (data) => {
    const newData = {
      default: data?.isDefault ?? false,
      ipAddress: data?.ipAddress ?? '',
      name: data?.name ?? '',
      port: data?.port ?? '',
      type: data?.type ?? '',
      ticketStyle: data?.ticketStyle,
      innerMacAddress: data?.innerMacAddress,
      printerTags: data?.printerTags ?? [],
      id: data?.id
    };
    formMethods.reset(newData);
    setMode(MODE.EDIT);
    setOpenPrinters(true);
  }

  const handleDelete = async (e, reportItem) => {
    e.stopPropagation();
    const { id, haveEvents } = reportItem;
    const option = {
      title: '',
      message: t('settings.are_delete_printer'),
      cancelText: t('orders.cancel'),
      confirmText: t('table_ordering.yes_delete'),
      confirmColor: 'primary',
      background: 'rgba(226, 21, 8, 1)',
    };

    if (haveEvents) {
      option.message = 'The printer you want to remove has assigned events. Do you want to remove it';
      option.cancelText = 'No';
      option.confirmText = 'Yes';
    }
    const confirmed = await confirm(option);
    if (confirmed) {
      dispatch(deletePrinter(id));
      return true;
    }
  };

  const printerColumns = [
    {
      field: 'name',
      headerName: t('Name'),
      flex: 1,
      sortable: false,
      filterable: false,
      editable: false,
      renderCell: ({ row }) => {
        if (row?.name) {
          return (
            <Tooltip title={row?.name} placement={'top'}>
              <DownloadCopyButtonTitle>
                {row?.name}
              </DownloadCopyButtonTitle>
            </Tooltip>
          )
        }
      }
    },
    {
      field: 'ipAddress',
      headerName: t('IpAddress'),
      flex: 1,
      sortable: false,
      filterable: false,
      editable: false,
      renderCell: ({ row }) => {
        return (
          <Typography>
            {row?.identify}
          </Typography>
        )
      },
    },
    {
      field: 'port',
      headerName: t('Port'),
      flex: 1,
      sortable: false,
      filterable: false,
      editable: false,
    },
    {
      field: 'type',
      headerName: t('Type'),
      flex: 1,
      sortable: false,
      filterable: false,
      editable: false,
    },
    {
      field: 'status',
      headerName: t('Status'),
      flex: 1,
      sortable: false,
      filterable: false,
      minWidth: 100,
      editable: false,
      renderCell: ({ row }) => {
        return (
          <PrinterStatusBlock>
            <PrinterStatus>
              <FiberManualRecordIcon sx={iconStyle(row?.status)} />
              <PrinterStatusTitle>{row?.status ? `Online` : 'Offline'}</PrinterStatusTitle>
            </PrinterStatus>
          </PrinterStatusBlock>
        )
      },
    },
    {
      field: 'ticketStyle',
      headerName: t('Ticket font size'),
      flex: 1,
      sortable: false,
      filterable: false,
      editable: false,
      renderCell: ({ row }) => {
        return (
          <PrinterTicketStyle>{row?.ticketStyle}</PrinterTicketStyle>
        )
      }
    },
    {
      field: 'printerTags',
      headerName: t('Tags'),
      flex: 1,
      sortable: false,
      filterable: false,
      editable: false,
      renderCell: ({ row }) => {
        if (row?.printerTags && row?.printerTags?.length > 0) {
          return (
            <>
              <TagsItem
                label={`${row?.printerTags?.length} tags`}
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
              />
              <Popover
                id="mouse-over-popover"
                sx={{ pointerEvents: 'none' }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
                PaperProps={{
                  sx: { width: '210px' }
                }}
              >
                {row?.printerTags?.map((item, index) => {
                  return (
                    <TagsItemWrapper key={index}>
                      <TagsItem label={`${item}`} />
                    </TagsItemWrapper>
                  )
                })}
              </Popover>
            </>
          )
        }
      }
    },
    {
      field: 'isDefault',
      headerName: t('Default'),
      flex: 1,
      sortable: false,
      filterable: false,
      editable: false,
      renderCell: ({ row }) => {
        if (row?.isDefault) {
          return (
            <img src={DoneCheckMark} alt="done" />
          )
        } else {
          return <></>
        }
      }
    },
    {
      id: 'tools',
      field: 'Tools',
      type: 'actions',
      width: 100,
      renderCell: (params) => {
        return (
          <Box sx={{ display: 'flex' }}>
            <IconButton aria-label="delete" onClick={() => handleEdit(params?.row)}>
              <ModeEditOutlineOutlinedIcon />
            </IconButton>
            <IconButton
              aria-label="delete"
              onClick={(e) => {
                 handleDelete(e, params?.row);
              }}
            >
              <img src={DeleteIcon} alt="delete icon" />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const handleCancel = () => {
    formMethods.reset(defaultValues);
    formMethods.clearErrors();
    setOpenPrinters(false);
    setTimeout(() => {
      setMode(MODE.CREATE);
    }, 300);
  };

  const createPrinter = (values) => {
    setLoading(true);
    try {
      dispatch(addPrinter({ ...values })).then(() => {
        formMethods.reset(defaultValues);
        formMethods.clearErrors();
        setOpenPrinters(false);
        setTimeout(() => {
          setMode(MODE.CREATE);
        }, 300);
      });
    }catch (error) {
      console.log(error, 'Error');
    } finally {
      setLoading(false);
    }
  }

  const editPrinter = (values) => {
    setLoading(true);
    try {
      dispatch(updatePrinter({ ...values })).then(() => {
        formMethods.reset(defaultValues);
        formMethods.clearErrors();
        setOpenPrinters(false);
        setTimeout(() => {
          setMode(MODE.CREATE);
        }, 300);
      });
    }catch (error) {
      console.log(error, 'Error');
    } finally {
      setLoading(false);
    }
  }

  const handleSubmit = (values) => {
    if (mode === MODE.CREATE) {
      createPrinter(values);
    } else if (mode === MODE.EDIT) {
      editPrinter(values);
    }
  }


  return {
    openPrinter,
    setOpenPrinters,
    mode,
    handleCancel,
    loading,
    formMethods,
    handleSubmit,
    printerListFiltered,
    printerColumns,
    setRowsPerPage,
    rowsPerPage
  }
}

export default usePrinters;