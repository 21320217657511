import { memo } from 'react';
import Tags from 'redesign/Tags/index'
import { PrinterTitle } from './styles';
import { Box, Button } from '@mui/material';
import Preloader from 'components/Preloader';
import { useTranslation } from 'react-i18next';
import EmptyMenu from 'components/MenuNew/EmptyMenu';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import usePrinters from 'redesign/Printers/hooks/usePrinters';
import { MODE, MUI_TO_SX_STYLES } from 'pages/TableOrdering/config';
import TableOrderModal from 'pages/TableOrdering/Tables/TableOrderModal';
import CreateAndEditPrinter from 'redesign/Printers/CreateAndEditPrinter';
import { HeaderContainer, StripedDataGrid } from 'pages/TableOrdering/styles';

const Printers = () => {

  const { t } = useTranslation();
  const  {
    openPrinter,
    setOpenPrinters,
    mode,
    handleCancel,
    loading,
    formMethods,
    handleSubmit,
    printerListFiltered,
    printerColumns,
    setRowsPerPage,
  } = usePrinters();

  const { getValues } = formMethods;

  return (
    <Box>
      <HeaderContainer>
        <Button
          id="addPrinter"
          onClick={() => setOpenPrinters(true)}
          variant="contained"
          color="primary"
          type="button"
          startIcon={<AddOutlinedIcon />}
        >
          {t('Add Printer')}
        </Button>
      </HeaderContainer>
      <TableOrderModal
        open={openPrinter}
        handleClose={handleCancel}
        title={mode === MODE.CREATE ? t('Add printer') : t('Edit Printer')}
        disabled={!formMethods.formState.isDirty || !formMethods.formState.isValid}
        saveTitle={mode === MODE.CREATE ? t('Add') : t('Save')}
        cancelTitle={'Cancel'}
        formMethods={formMethods}
        handleSubmit={handleSubmit}
        loading={loading}
      >
        <CreateAndEditPrinter getValues={getValues} />
      </TableOrderModal>
      <PrinterTitle>{t('Printers List')}</PrinterTitle>
      <StripedDataGrid
        sx={MUI_TO_SX_STYLES}
        autoHeight={true}
        hideFooterSelectedRowCount={true}
        hideFooter={true}
        isEditable={false}
        rowsPerPageOptions={[200]}
        pageSizeOptions={[200]}
        pageSize={100}
        onPageSizeChange={(newPageSize) => setRowsPerPage(newPageSize)}
        rows={printerListFiltered}
        columns={printerColumns}
        components={{
          LoadingOverlay: Preloader,
          NoRowsOverlay: () => (
            <Box sx={{ mt: '100px' }}>
              <EmptyMenu text={t('There are no printers yet')} />
            </Box>
          ),
        }}
        loading={loading}
      />
      <Tags />
    </Box>
  )
}

export default memo(Printers)